// PlayerOnTeam.ts

import { AthleteProfileModel } from "..";
import { DomainObject } from "../DomainObject";
import { SportPosition, InvitationStatus, Gender } from '@/../types/enums';

export interface PlayerOnTeam extends DomainObject {
	userId?: string | null;
	athleteId: string | null;
	email: string;
	firstName: string;
	lastName: string;
	gender: Gender | null;
	number: string;
	position?: SportPosition;
	secondaryPosition?: SportPosition;
	preferredPositionNumbers?: number[] | null;
	injured?: boolean;
	eligibleToPlay?: boolean;
	gradYear?: number;
	dateOfBirth: string;
	schoolCommitment?: string;
	pictureUrl?: string;
	qrCode?: string;
	invitationStatus?: InvitationStatus;
	club?: string;
	caps?: number;
	goals?: number;
}

export class PlayerOnOrg implements PlayerOnTeam {
	athleteId: string | null = null;
	email: string = '';
	firstName: string = '';
	lastName: string = '';
	gender: Gender | null = null;
	number: string = '';
	dateOfBirth: string = '';
	info?: Array<{key,value}>;
}

export class PlayerOnOrgEx extends PlayerOnOrg {
	id: string;
	athlete: Partial<AthleteProfileModel> = undefined;

	constructor(player: PlayerOnOrg) {
		super();
		Object.assign(this, player);
		this.id = player.athleteId;
	}
}

export interface TeamAthleteProgress extends PlayerOnTeam {
	shortId: string;
	hasPhoto: boolean;
	hasEmail: boolean;
	hasPhone: boolean;
	hasInsta: boolean;
	hasTwitter: boolean;
	hasBio: boolean;
	hasDOB: boolean;
	hasHeight: boolean;
	hasWeight: boolean;
	hasDominant: boolean;
	hasLocation: boolean;
	hasHighlights: boolean;
	hasGradYear: boolean;
	hasGPA: boolean;
	hasSAT: boolean;
	hasVerifiedAssessment: boolean;
	mindsetArchetype: string;
	hasScoutingReport: boolean;
	commitment: string;
}

export enum AthleteProgressFields {
	shortId = "Account",
	hasPhoto = "Profile Photo",
	hasEmail = "Email",
	hasPhone = "Phone",
	hasInsta = "Instagram",
	hasTwitter = "Twitter",
	hasBio = "Biography",
	hasDOB = "Date of Birth",
	hasHeight = "Height",
	hasWeight = "Weight",
	hasDominant = "Dominant Foot",
	hasLocation = "Location",
	hasHighlights = "Highlight Video",
	hasGradYear = "Grad Year",
	hasGPA = "GPA",
	hasSAT = "SAT",
	hasVerifiedAssessment = "Physical Assessment",
	mindsetArchetype = "Mindset",
	hasScoutingReport = "Scouting Report",
	commitment = "Commitment",
}

export enum AthleteProgressAbbreviations {
	shortId = "sId",
	hasPhoto = "Photo",
	hasEmail = "Email",
	hasPhone = "Phone",
	hasInsta = "Igram",
	hasTwitter = "X",
	hasBio = "Bio",
	hasDOB = "DoB",
	hasHeight = "Hght",
	hasWeight = "Wght",
	hasDominant = "Foot",
	hasLocation = "Loc",
	hasHighlights = "Video",
	hasGradYear = "Grad",
	hasGPA = "GPA",
	hasSAT = "SAT",
	hasVerifiedAssessment = "Phys",
	mindsetArchetype = "Mind",
	hasScoutingReport = "Scout",
	commitment = "Commit",
	email = "Email",
	phoneNumber = "Phone",
	completedProfile = "COMPLETE",
	fromOrganization = "Org",

}

